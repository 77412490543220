import { useEffect } from 'react';

const RedirectPage = ({ url = '' }) => {
  useEffect(() => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
  }, []);

  return null;
};

export default RedirectPage;
