export const validateName = (name) => {
  if (name?.match(/^[a-zA-Z]+ [a-zA-Z]+$/)) return true;
  else return false;
};

export const validateEmail = (email) => {
  if (email?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) return true;
  else return false;
};

export const validatePhone = (phone) => {
  if (phone?.match(/^\d{10}$/)) return true;
  else return false;
};

export const validateSubject = (subject) => {
  return subject !== '';
};

export const validateMessage = (message) => {
  return message !== '';
};

export const isURL = (url = '') => {
  if (!url || !typeof url === 'string') {
    return null;
  }

  const parts = url.split(':');

  if (parts[0] === 'http' || parts[0] === 'https') {
    return true;
  } else {
    return false;
  }
};

export const getFileExtensionFromURL = (url = '') => {
  if (!url || !typeof url === 'string') {
    return null;
  }

  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  const extensionParts = lastPart.split('.');
  const extension = extensionParts[extensionParts.length - 1];
  return extension;
};
