//  =================Policy Page=====================

export const POLICY_TEXT_0 = `Alien Brains Pvt. Ltd. built the Sociana app as a Free app. This SERVICE is provided by Alien Brains Pvt. Ltd. at no cost and is intended for use as is.

This page is used to inform visitors regarding our policies regarding the collection, use, and disclosure of Personal Information if anyone decided to use our Service.

If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.`;

export const POLICY_HEADING_1 = `Types of Data Collected`;

export const POLICY_SUB_HEADING_1_1 = `Personal Data`;

export const POLICY_TEXT_1 = `While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to`;

export const POLICY_BULLET_POINTS_1 = [
  `Email address`,
  `First name and last name`,
  `Phone number`,
  `Usage data`,
  `Profile image`,
  `Date of birth`,
  `Gender`
];

export const POLICY_SUB_HEADING_1_2 = `Usage Data`;

export const POLICY_TEXT_2 = `We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data. We are collecting this information to send push notification.
`;

export const POLICY_HEADING_2 = `Information Collected while Using the Application`;

export const POLICY_TEXT_3 = `While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:`;

export const POLICY_BULLET_POINTS_2 = [
  `Information regarding your location`,
  `Information from your Device's phone book (contacts list)`,
  `Pictures and other information from your Device's camera and photo library`
];

export const POLICY_TEXT_4 = `We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device. You can enable or disable access to this information at any time, through Your Device settings.`;

export const POLICY_HEADING_3 = `Use of Your Personal Data`;

export const POLICY_TEXT_5 = `The Company may use Personal Data for the following purposes:`;

export const POLICY_BULLET_POINTS_3 = [
  `To provide and maintain our Service, including to monitor the usage of our Service`,
  `To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to  different functionalities of the Service that are available to You as a registered user`,
  `To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation`,
  `To manage Your requests: To attend and manage Your requests to Us`
];

export const POLICY_TEXT_6 = `Third-party Service Providers that we use to provide better improvement of our Service.`;

export const POLICY_BULLET_POINTS_4 = [
  `Google Places -  Google Places is a service that returns information about places using HTTP requests. It is operated by Google Google Places service may collect information from You and from Your Device for security purposes. The information gathered by Google Places is held in accordance with the Privacy Policy of Google: https://www.google.com/intl/en/policies/privacy/`
];

export const POLICY_TEXT_7 = `The app does use third-party services that may collect information used to identify you.`;

export const POLICY_HEADING_4 = `Delete Your Personal Data`;

export const POLICY_TEXT_8 = `You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You. Our Service may give You the ability to delete certain information about You from within the Service. You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the profile section that allows you to delete all your information stored on our servers. When you delete your account, your profile, photos, videos, comments, likes, personal information(name, phone no, email, gender, date of birth) will be permanently removed. You may also contact us(admin@alienbrains.in, admin@sociana.com) to request access to, correct, or delete any personal information that You have provided to Us. 

Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.
`;

export const POLICY_HEADING_5 = `Disclosure of Your Personal Data`;

export const POLICY_SUB_HEADING_5_1 = `Business Transactions`;
export const POLICY_TEXT_9 = `If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.`;

export const POLICY_SUB_HEADING_5_2 = `Law enforcement`;

export const POLICY_TEXT_10 = `Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).`;

export const POLICY_SUB_HEADING_5_3 = `Children’s Privacy`;

export const POLICY_TEXT_11 = `Children’s Privacy
These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.`;

export const POLICY_HEADING_6 = `Changes to this Privacy Policy`;

export const POLICY_TEXT_12 = `We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.`;

export const POLICY_HEADING_7 = `Contact Us`;

export const POLICY_TEXT_13 = `If you have any questions about this Privacy Policy,
You can contact us:`;

export const POLICY_BULLET_POINTS_5 = [
  `By email: contactus@alienbrains.in, admin@alienbrains.in, admin@sociana.com`,
  `By phone: +91 8420804831`
];

export const POLICY_HEADING_8 = `Reporting of violating content`;

export const POLICY_TEXT_14 = `There is no tolerance for objectionable content or abusive users. If you found anything of sort please provide necessary details via e-mail (admin@alienbrains.in, admin@sociana.com). It will be reviewed upon by the occasion organiser and managers & Sociana moderators before taking any action.

We act on objectionable content reports within 24 hours. Our steps may include, but not limited to, removing the content and limiting the user who provided the offending content.`;

//  ========================Terms & Conditions===============================

export const TERMS_TEXT_1 = `Terms and Conditions`;

export const TERMS_TEXT_2 = `These terms and conditions ("Terms", "Agreement") are an agreement between Mobile Application Developer
("Mobile Application Developer", "us", "we" or "our") and you ("User", "you" or "your"). This Agreement sets forth
the general terms and conditions of your use of the Sociana mobile application and any of its products or
services (collectively, "Mobile Application" or "Services").`;

export const TERMS_TEXT_3 = `As a condition of use, you promise not to use our Services for any purpose which is unlawful or prohibited by these terms. By way of example and not a limitation you agree to not use our Services:`;

export const TERMS_TEXT_4 = `To abuse, harass, threaten, impersonate or intimidate any user.`;

export const TERMS_TEXT_5 = `To post any content that is defamatory, libelous, obscene, pornographic, abusive, offensive, profane or that infringes any copyright or other rights of a person.`;

export const TERMS_TEXT_6 = `To spam any person or a group of persons with unwanted information.`;

export const TERMS_TEXT_7 = `To post copyrighted content which doesn’t belong to you.`;

export const TERMS_TEXT_8 = `To artificially inflate or alter likes and comments or any other service for the purpose of giving or receiving money or any form of compensation or participate in any organized effort that in any way artificially alters the results and processes of the application.`;

export const TERMS_TEXT_9 = `To advertise to, or solicit, any user to buy or sell any product or services or to use information obtained on the app in order to contact, advertise to, solicit, or sell to any user without their prior explicit consent.`;

export const TERMS_TEXT_10 = `To promote or sell the content of another user`;

export const TERMS_TEXT_11 = `Accounts and membership`;

export const TERMS_TEXT_12 = `You must be at least 13+ years of age to use this Mobile Application. By using this Mobile Application and by
agreeing to this Agreement you warrant and represent that you are at least 13+ years of age. If you create an
account in the Mobile Application, you are responsible for maintaining the security of your account and you are
fully responsible for all activities that occur under the account and any other actions taken in connection with
it. We may, but have no obligation to, monitor and review new accounts before you may sign in and use our
Services. Providing false contact information of any kind may result in the termination of your account. You
must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will
not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such
acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that
you have violated any provision of this Agreement or that your conduct or content would tend to damage our
reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our
Services. We may block your email address and Internet protocol address to prevent further registration.`;

export const TERMS_TEXT_13 = `User content`;

export const TERMS_TEXT_14 = `We do not own any data, information or material ("Content") that you submit in the Mobile Application in the
course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality,
reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may
monitor and review Content in the Mobile Application submitted or created using our Services by you. Unless
specically permitted by you, your use of the Mobile Application does not grant us the license to use,
reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for
commercial, marketing or any similar purpose. But you grant us permission to access, copy, distribute, store,
transmit, reformat, display and perform the Content of your user account solely as required for the purpose of
providing the Services to you. Without limiting any of those representations or warranties, we have the right,
though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable
opinion, violates any of our policies or is in any way harmful or objectionable.`;

export const TERMS_TEXT_15 = `Adult content`;

export const TERMS_TEXT_16 = `Please be aware that there may be certain adult or mature content available in the Mobile Application. Where
there is mature or adult content, individuals who are less than 13+ years of age or are not permitted to access
such content under the laws of any applicable jurisdiction may not access such content. If we learn that
anyone under the age of 13+ seeks to conduct a transaction through the Services, we will require veried parental consent, in accordance with the Children's Online Privacy Protection Act of 1998 ("COPPA"). Certain
areas of the Mobile Application may not be available to children under 13+ under any circumstances.
`;

export const TERMS_TEXT_17 = `Backups`;

export const TERMS_TEXT_18 = `We are not responsible for Content residing in the Mobile Application. In no event shall we be held liable for any
loss of any Content. It is your sole responsibility to maintain appropriate backup of your Content.
Notwithstanding the foregoing, on some occasions and in certain circumstances, with absolutely no obligation,
we may be able to restore some or all of your data that has been deleted as of a certain date and time when we
may have backed up data for our own purposes. We make no guarantee that the data you need will be
available.`;

export const TERMS_TEXT_19 = `Links to other mobile applications`;

export const TERMS_TEXT_20 = `Although this Mobile Application may link to other mobile applications, we are not, directly or indirectly,
implying any approval, association, sponsorship, endorsement, or aliation with any linked mobile application,
unless specically stated herein. We are not responsible for examining or evaluating, and we do not warrant
the offerings of, any businesses or individuals or the content of their mobile applications. We do not assume
any responsibility or liability for the actions, products, services, and content of any other third-parties. You
should carefully review the legal statements and other conditions of use of any mobile application which you
access through a link from this Mobile Application. Your linking to any other off-site mobile applications is at
your own risk.`;

export const TERMS_TEXT_21 = `Prohibited uses`;

export const TERMS_TEXT_22 = `In addition to other terms as set forth in the Agreement, you are prohibited from using the Mobile Application
or its Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts;
(c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to
infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass,
abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation,
religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to
upload or transmit viruses or any other type of malicious code that will or may be used in any way that will
affect the functionality or operation of the Service or of any related mobile application, other mobile
applications, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm,
pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent
the security features of the Service or any related mobile application, other mobile applications, or the Internet.
We reserve the right to terminate your use of the Service or any related mobile application for violating any of
the prohibited uses.`;

export const TERMS_TEXT_23 = `Intellectual property rights`;

export const TERMS_TEXT_24 = `This Agreement does not transfer to you any intellectual property owned by Mobile Application Developer or
third-parties, and all rights, titles, and interests in and to such property will remain (as between the parties)
solely with Mobile Application Developer. All trademarks, service marks, graphics and logos used in connection
with our Mobile Application or Services, are trademarks or registered trademarks of Mobile Application
Developer or Mobile Application Developer licensors. Other trademarks, service marks, graphics and logos used in connection with our Mobile Application or Services may be the trademarks of other third-parties. Your
use of our Mobile Application and Services grants you no right or license to reproduce or otherwise use any
Mobile Application Developer or third-party trademarks.`;

export const TERMS_TEXT_25 = `Limitation of liability`;

export const TERMS_TEXT_26 = `To the fullest extent permitted by applicable law, in no event will Mobile Application Developer, its aliates,
ocers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any indirect,
incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost
prots, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated
savings, loss of business opportunity) however caused, under any theory of liability, including, without
limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if Mobile Application
Developer has been advised as to the possibility of such damages or could have foreseen such damages. To
the maximum extent permitted by applicable law, the aggregate liability of Mobile Application Developer and its
aliates, ocers, employees, agents, suppliers and licensors, relating to the services will be limited to an
amount greater of one dollar or any amounts actually paid in cash by you to Mobile Application Developer for
the prior one month period prior to the rst event or occurrence giving rise to such liability. The limitations and
exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential
purpose.`;

export const TERMS_TEXT_27 = `Indemnication`;

export const TERMS_TEXT_28 = `You agree to indemnify and hold Mobile Application Developer and its aliates, directors, ocers, employees,
and agents harmless from and against any liabilities, losses, damages or costs, including reasonable
attorneys' fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes,
or demands asserted against any of them as a result of or relating to your Content, your use of the Mobile
Application or Services or any willful misconduct on your part.
`;

export const TERMS_TEXT_29 = `Severability`;

export const TERMS_TEXT_30 = `All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding
only to the extent that they do not violate any applicable laws and are intended to be limited to the extent
necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or
portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of
competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall
constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or
portions thereof shall remain in full force and effect.`;

export const TERMS_TEXT_31 = `Dispute resolution`;

export const TERMS_TEXT_32 = `The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be
governed by the substantive and procedural laws of West Bengal, India without regard to its rules on conicts
or choice of law and, to the extent applicable, the laws of India. The exclusive jurisdiction and venue for actions
related to the subject matter hereof shall be the state and federal courts located in West Bengal, India, and you
hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the
International Sale of Goods does not apply to this Agreement.`;

export const TERMS_TEXT_33 = `Changes and amendments`;

export const TERMS_TEXT_34 = `We reserve the right to modify this Agreement or its policies relating to the Mobile Application or Services at
any time, effective upon posting of an updated version of this Agreement in the Mobile Application. When we
do, we will post a notication in our Mobile Application. Continued use of the Mobile Application after any such
changes shall constitute your consent to such changes. Policy was created with WebsitePolicies.`;

export const TERMS_TEXT_35 = `Acceptance of these terms`;

export const TERMS_TEXT_36 = `You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the
Mobile Application or its Services you agree to be bound by this Agreement. If you do not agree to abide by the
terms of this Agreement, you are not authorized to use or access the Mobile Application and its Services.`;

export const TERMS_TEXT_37 = `Contacting us`;

export const TERMS_TEXT_38 = `If you would like to contact us to understand more about this Agreement or wish to contact us concerning any
matter relating to it, you may send an email to: `;

export const TERMS_TEXT_39 = `admin@sociana.com`;

export const TERMS_TEXT_40 = `This document was last updated on January 28, 2020`;

//  ==========================FAQ======================================

export const FAQ_TEXT_11 = `Frequently Asked Questions (FAQ's)`;

export const FAQ_TEXT_1 = `Why is Sociana the only End-To-End Event Experience App?`;

export const FAQ_TEXT_2 = `Sociana brings an array of varied features for Event Organizers across verticals and fields to effectively Monitor, Manage & Organize the entire event from a centralized platform`;

export const FAQ_TEXT_3 = `I am Event Management Organisation, how do we take use of Sociana in Scaling our Business and Managing ongoing events?`;

export const FAQ_TEXT_4 = `Sociana is available on Both iOS & Android. Sociana lets its “Premium” Users repeat the entire cycle of Monitoring, Managing & Organising the entire event multiple times for as many events as they want. We help reduce Human Interventions and Steps to Goal per action. Try us now!`;

export const FAQ_TEXT_7 = `Is Sociana Free? If not what’s the cost?`;

export const FAQ_TEXT_8 = `No! Use of Sociana is limited to “Premium” Users for creating events, making the experience better and managing them on our platform. For special rates and trials mail us at – admin@sociana.com`;

export const FAQ_TEXT_9 = `Can I manage Wedding on Sociana?`;

export const FAQ_TEXT_10 = `For sure! Sociana has tailor-made features such as Hospitality, Location Based Triggers and End-to-End SOS/Request feature within the app to make a Wedding hassle free for the organizers and give all the guests an experience where they feel important and can ensure all guests are happy`;

export const FAQ_TEXT_12 = `I have a conference coming up for 10000+ attendees, Can Sociana help?`;

export const FAQ_TEXT_13 = `Sociana work wonders with large audiences, Our Bond Feature ensures secured Networking on a Digital Platform the first time in India`;

export const FAQ_TEXT_14 = `Is Sociana Secure? How can I trust Sociana with my customers data?`;

export const FAQ_TEXT_15 = `Sociana is proud to be an App with 100% privacy features such as Critical Access Control within organising teams and guests, No Data Transfer to any other platform and Screenshot Restrictions can ensure a smooth, safe and friendly journey. We never save any Data! Reach us to know more at – admin@sociana.com`;

//  ==================================Navbar=====================================

export const NAV_TEXT_1 = `Features`;

export const NAV_TEXT_2 = `Reviews`;

export const NAV_TEXT_3 = `About`;

export const NAV_TEXT_4 = `Screenshots`;

export const NAV_TEXT_5 = `Demo`;

export const NAV_TEXT_6 = `Contact`;

//  ================================Landing========================================

export const LANDING_TEXT_1 = `INTRODUCING THE FIRST GENUINE AUTOMATION TOOL FOR YOUR ENTIRE EVENT EXPERIENCE`;

export const LANDING_TEXT_2 = `Download the App now – Lets Monitor, Manage and Organise Now!`;

export const LANDING_TEXT_3 = `LET YOUR EVENT STORY UNFOLD THE WAY YOU WANT WITH AI AUTOMATION`;

export const LANDING_TEXT_4 = `Download the App now!`;

export const LANDING_TEXT_5 = `BE THE BEST HOST! TRY SOCIANA`;

export const LANDING_TEXT_6 = `Available for iOS & Android, Download Now!`;

//  ==============================Features========================================

export const FEATURES_TEXT_1 = `A BAG OF SURPRISES NEVER SEEN BEFORE`;

export const FEATURES_TEXT_2 = `-Take a look at our app features-`;

export const FEATURES_TEXT_3 = `BOND`;

export const FEATURES_TEXT_4 = `A new way to enable “within event networking” in Sociana. Secured, friendly and fun!`;

export const FEATURES_TEXT_5 = `LOCATION BASED TRIGGERS`;

export const FEATURES_TEXT_6 = `Notifications that ensure guest/attendee engagement pre/post event `;

export const FEATURES_TEXT_7 = `POWERFUL CUSTOMIZATION`;

export const FEATURES_TEXT_8 = `Let Sociana be the canvas to sprinkle your creativity to the event, the way you want it to look!`;

export const FEATURES_TEXT_9 = `DOWNLOAD IS FREE`;

export const FEATURES_TEXT_10 = `Be it for scrolling through events, or through invitation to an event, download Sociana without hassle. It is free!`;

export const FEATURES_TEXT_11 = `ZERO CODE POLICY`;

export const FEATURES_TEXT_12 = `Do all you want, use all you want. Without writing a single line of a code. Gone the days of custom mobile apps. One Platform, Zillion Events….`;

export const FEATURES_TEXT_13 = `HOSPITALITY`;

export const FEATURES_TEXT_14 = `Let us take all your hospitality hassles, while you relax and focus on other aspects. Once onboard, a ‘premium’ user can also ensure all pilferages can be tightened `;

//  =============================Reviews=========================================

export const REVIEWS_TEXT_1 = `Amazing App for people who can't attend all the family occasions coz of staying away from family... Nice concept @alienbrains`;

export const REVIEWS_TEXT_2 = `Shuvam Singh`;

export const REVIEWS_TEXT_3 = `Data Analyst, P&M Valuation Manager`;

export const REVIEWS_TEXT_4 = `Great platform to share memories with loved ones and capture moments. I loved the concept behind the application and I highly recommend to use it for various events.`;

export const REVIEWS_TEXT_5 = `Harshita Narnoli`;

export const REVIEWS_TEXT_6 = `Software Developer, Alien Brains`;

export const REVIEWS_TEXT_7 = `Best company to deal in developing a customize app for any particular event and values for money.... Had an great experience with you guys... Achieve heights`;

export const REVIEWS_TEXT_8 = `Abhishek Rai`;

export const REVIEWS_TEXT_9 = `Area Sales Head, Escorts`;

export const REVIEWS_TEXT_10 = `Reviews`;

export const REVIEWS_TEXT_11 = `-What they say about sociana-`;

//  ============================About - Quality=====================================

export const QUALITY_TEXT_1 = `Need more reasons to choose Sociana?`;

export const QUALITY_TEXT_2 = `Why Sociana!`;

export const QUALITY_TEXT_4 = `Manageability`;

export const QUALITY_TEXT_5 = `Guest Memories can be secured and managed easily.`;

export const QUALITY_TEXT_6 = `Live Streaming`;

export const QUALITY_TEXT_7 = `Live-Stream all your events in a secured environment`;

export const QUALITY_TEXT_8 = `Additional Benefits`;

export const QUALITY_TEXT_9 = `Upto 5000+ SmS Invites free with every event`;

export const QUALITY_TEXT_10 = `Marketing Platform`;

export const QUALITY_TEXT_11 = `Marketing platform to create your portfolio and showcase your events.`;

//  =============================About - Awards======================================

export const AWARDS_TEXT_1 = `270`;

export const AWARDS_TEXT_2 = `Project Completed`;

export const AWARDS_TEXT_3 = `430`;

export const AWARDS_TEXT_4 = `Winner Awards`;

export const AWARDS_TEXT_5 = `69`;

export const AWARDS_TEXT_6 = `Team Creative`;

export const AWARDS_TEXT_7 = `815`;

export const AWARDS_TEXT_8 = `Clients Order`;

//  =============================About - Compatibility================================

export const COMPATIBILITY_TEXT_1 = `Single platform fits all events `;

export const COMPATIBILITY_TEXT_3 = `Our exhilarating application provides a platform for hosting multifarious events and occasions that help 
in connecting people. `;

export const COMPATIBILITY_TEXT_2 = `Sociana provides a common platform to host all types of events- Be it a Wedding, a Conference, Party, 
College Fests, etc.  `;

export const COMPATIBILITY_TEXT_4 = `Download App`;

//  ===========================Screenshot==========================================

export const SCREENSHOT_TEXT_1 = `SCREENSHOTS`;

export const SCREENSHOT_TEXT_2 = `- See what's included in app -`;

//  ============================Demo - Video===========================================

export const VIDEO_TEXT_1 = `HOW IT WORKS`;

export const VIDEO_TEXT_2 = `- Take a look at what we have to offer -`;

//  =============================Demo - Personalize================================

export const PERSONALIZE_TEXT_1 = `MADE EXCLUSIVELY FOR YOUR EVENTS `;

export const PERSONALIZE_TEXT_2 = `Eliminate the hassle of creating your own app. We have already done it for you. Make each of your events custom-made and personalized with Sociana.`;

//  =============================CONTACT====================================

export const SUPPORT_TEXT_1 = `Contact Us`;

export const SUPPORT_TEXT_2 = `- Send message here -`;

export const SUPPORT_TEXT_3 = `Enter Name`;

export const SUPPORT_TEXT_4 = `Enter Email`;

export const SUPPORT_TEXT_5 = `Enter Phone Number`;

export const SUPPORT_TEXT_6 = `Enter Subject`;

export const SUPPORT_TEXT_7 = `Enter your message...`;

export const SUPPORT_TEXT_8 = `Send Message`;

export const SUPPORT_TEXT_9 = `Terms & Conditions`;

export const SUPPORT_TEXT_10 = `Privacy Policy`;

export const SUPPORT_TEXT_11 = `Frequently asked Questions`;

export const SUPPORT_TEXT_12 = `Reach Us `;

export const SUPPORT_TEXT_13 = `: admin@sociana.com`;

export const SUPPORT_TEXT_14 = `Call `;

export const SUPPORT_TEXT_15 = `: +91 8420804831`;

//  ===============================Footer================================

export const FOOTER_TEXT_1 = `Copyright 2020 © Alien Brains Pvt. Ltd.`;

//================================PlayStore Link========================

export const PLAYSTORE_LINK = `https://play.google.com/store/apps/details?id=com.alienbrains.sociana&hl=en`;

export const APPSTORE_LINK = `https://apps.apple.com/in/app/sociana/id1477953677`;

export const MonthIndex = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
];
