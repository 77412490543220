import React from 'react';

import { getFileExtensionFromURL, isURL } from '../../Helpers/helpers';
import styles from './image.module.css';

class Image extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isWebpURL: false
    };
  }

  componentDidMount() {
    const { src } = this.props;

    if (!isURL(src)) {
      return;
    }

    const extension = getFileExtensionFromURL(src);
    if (extension === 'webp') {
      this.setState({
        isWebpURL: true
      });
    }
  }

  onImageLoad = () => {
    this.setState({ isLoaded: true });
  };

  render() {
    const { isLoaded, isWebpURL } = this.state;
    const { src, webpImage, className, alt, fallbackProps = {} } = this.props;
    return src || webpImage ? (
      <div className={styles.Image_container}>
        <picture>
          {Boolean(webpImage || isWebpURL) && (
            <source type="image/webp" srcSet={webpImage || src} />
          )}

          {!isWebpURL && <source type="image/png" srcSet={src} />}

          <img
            className={className}
            onLoad={this.onImageLoad}
            src={src || webpImage}
            alt={alt}
          />
        </picture>
        {!isLoaded && (
          <div
            className={`${styles.Image_fallback} ${className} `}
            {...fallbackProps}
          />
        )}
      </div>
    ) : null;
  }
}

export default Image;
