import React from 'react';
import sociana_logo_jpg from '../Assets/Terms/sociana1.png';
import sociana_logo_webp from '../Assets/Terms/sociana1.webp';
import Image from '../Components/Image/Image';
import styles from './Routes.module.css';

const RouteFallback = () => {
  return (
    <div className={styles.container}>
      <div className={styles.fallback_image_container}>
        <Image
          className={styles.fallback_image}
          alt="sociana logo"
          src={sociana_logo_jpg}
          webpImage={sociana_logo_webp}
        />
      </div>
    </div>
  );
};

export default RouteFallback;
