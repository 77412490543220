import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import MainRoute from './Routes';
import './App.css';

const App = () => (
  <BrowserRouter>
    <div className="app-container">
      <MainRoute />
    </div>
  </BrowserRouter>
);

export default App;
